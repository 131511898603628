@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This will prevent horizontal scrollbar */
}

@media (max-width: 600px) {
  .dancing-font {
    font-size: 6vw; /* Relatívna veľkosť písma pre menšie zariadenia */
  }

  .love {
    font-size: 8vw; /* Relatívna veľkosť písma pre "láska" */
    margin-top: -2vw; /* Relatívny posun nahor */
  }
}

.App {
  display: flex;
  flex-direction: column; /* Zmena smeru na vertikálne usporiadanie */
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black; /* Staroružová farba */
}

.text-wrapper {
  text-align: center; /* Centrový text */
  max-width: 100%; /* Maximálna šírka */
}

.dancing-font {
  font-family: "Pacifico", cursive;
  color: #fff; /* Farba textu */
  font-size: 6vw; /* Relatívna veľkosť písma */
  margin: 0 auto; /* Auto marginy pre centrový text */
}

.love {
  display: flex;
  font-family: "Pacifico", cursive;
  color: #ff69b4; /* Farba pre "láska" */
  font-size: 8vw; /* Relatívna veľkosť písma pre "láska" */
  margin-top: -5vw;
  margin-bottom: 0; /* Odstránenie spodnej marže ak je potrebné */
  margin-left: 57vw; /* Auto marginy pre centrový text */
  margin-right: auto; /* Auto marginy pre centrový text */
}
.cat-corner {
  position: absolute;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.top-left-cat {
  transform: rotate(130deg) scale(3);
  top: 0;
  left: 0;
  background-image: url("../data/1.svg"); /* aktualizovaná cesta k obrázku */
}

.top-right-cat {
  transform: scale(2);
  transform-origin: top right;
  top: 0vw;
  right: 0vw;
  background-image: url("../data/2.png"); /* aktualizovaná cesta k obrázku */
}

.bottom-left-cat {
  transform: scale(1.5);
  transform-origin: bottom;
  bottom: 0;
  left: 0;
  background-image: url("../data/5.svg"); /* aktualizovaná cesta k obrázku */
}

.bottom-middle-cat {
  transform: scale(3);
  transform-origin: bottom;
  bottom: 0;
  left: 50;
  background-image: url("../data/3.png"); /* aktualizovaná cesta k obrázku */
}

.bottom-right-cat {
  bottom: 0;
  right: 0;
  background-image: url("../data/4.svg"); /* aktualizovaná cesta k obrázku */
}

.button-23 {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23:active {
  background-color: #f7f7f7;
  border-color: #fff;
  color: #ff69b4;
  transform: scale(1.2);
}

@media (min-width: 950px) {
  .button-23 {
    font-size: 2vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate { 
  animation: rotateAnimation .5s ease-in-out;
  transform: scale(2);
}

@keyframes zoomInAnimation {
  from {
    transform: rotate(130deg) scale(3);
  }
  to {
    transform: rotate(130deg) scale(6);
  }
}

@keyframes zoomInAbejka {
  from {
    transform: scale(3);
  }
  to {
    transform:  scale(7);
  }
}

.zoom {
  animation: zoomInAnimation 1s ease-in-out;
}

.zoomAbejka {
  animation: zoomInAbejka 1s ease-in-out;
}