.body, html{
  background-color: #cbb2fe;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  z-index: 10; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.journeyScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
  background-color: #cbb2fe;
  /* justify-content: center; */
  font-family: "Archivo";
}

.fixedHeader {
  position: sticky;
  top: 0;
  background-color: #cbb2fe;
  z-index: 10;
  width: 100%;
  text-align: -webkit-center;
}

.titleGradient {
  height: 2vw; 
  /* background: linear-gradient( bottom, #8cbab8, rgba(255,255,255,0)); */
  background: linear-gradient(to bottom, #cbb2fe, transparent);
  width: 100%;
}

.taskContainer {
  overflow-y: auto;
  text-align: -webkit-center;
  height: 80%;
  
}
.title {
  margin-top: 5vw;
  font-size: 6vw;
  font-family: "Pacifico", cursive;
}

.introContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 50%;
  align-items: center;
  height: 100vh;
}

.alertCard {
  background-color: #ffcccc;
  border-left: 5px solid #ff6666;
  padding: 20px;
  margin: 10px 0;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.taskCard {
  background-color: whitesmoke;
  border-radius: 8px;
  padding-top: 10vw;
  padding: 20px;
  margin: 10px 10px 10px 10px;
  width: 80%;
  display: flex; /* Použitie Flexbox na zarovnanie */
  flex-direction: column; /* Deti sa usporiadajú vertikálne */
  justify-content: center; /* Zarovnanie detí na stred na hlavnej osi */
  align-items: center;
}

.messageCard {
  background-color: #f0f0f0;
  border-radius: 8px;
  border: solid white;
  padding: 20px;
  color: #666;
  /* width: 90%; */
  text-align: center;
  position: fixed;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%); /* Centrovat správu */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Zabezpečí, že bude nad ostatnými prvkami */
}

.messageCard {
  box-shadow: 0px 1px 10px 0px #cecece;
}

.closeButton {
  background-color: #7ad68e;
  margin-left: 3vw;
  border: 2px solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  padding: 1vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.taskTitle {
  font-family: "Pacifico", cursive;
  color: #333;
  margin: 0;
  font-size: 4vw;
}

.taskDescription {
  text-align: center;
  color: #666;
  font-size: 3vw;
}

.nextInstructions{
  text-align: center;
  color: #666;
  font-size: 3vw;

}

.codeReveal {
  font-family: "Pacifico", cursive;
  margin-top: 3vw;
  margin-bottom: 3vw;
  background-color: #e9ecef;
  padding: 10px;
  color: #51bb68;
  border-radius: 4px;
}

.inputField {
  background-color: transparent;
  border: none;
  border-bottom: 1vw solid white;
  padding: 10px 0;
  margin-top: 10px;
  width: 80%;
  color: #818181;
  font-size: 3vw;
  font-weight: bold;
  text-align: center;

  ::placeholder {
    text-align: center;
    color: #333;
    font-size: 3vw;
    font-weight: bold;
  }
}
.startButton {
  background-color: transparent;
  margin-left: 3vw;
  border: 0.5vw solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  margin-right: 2vw;
}
.startButtonRight {
  background-color: transparent;
  margin-left: 3vw;
  border: 0.5vw solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  margin-right: 2vw;
  margin-top: 1vw;
}

h2 {
  font-family: "Pacifico", cursive;
  margin-bottom: 1vw;
}

.introSubtitle {
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.intro {
  margin-bottom: 5vw;
  padding: 3vw;
}

p {
  margin: 2vw;
}

.highlight {
  color: #ffc300;
  font-weight: bold;
}

.submitButton,
.noButton {
  background-color: #7ad68e;
  margin-left: 3vw;
  border: 0.2vw solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  margin-top: 3vw;
  margin-bottom: 1vw;
}

.endButton,
.yesButton {
  background-color: #bc4749;
  border: 0.2vw solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  padding: 1vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.submitButton:hover {
  background-color: #d8bc69;
}

.yesButton {
  margin-right: 2vw;
}

@media (min-width: 950px) {
  .taskTitle {
    font-size: 2vw;
  }
  .taskDescription {
    font-size: 1.5vw;
  }
  .codeReveal{
    font-size: 2vw;
  }
  .introContent {
    font-size: 2vw;
  }
  .startButtonRight,
  .startButton,
  .submitButton,
  .endButton,
  .closeButton,
  .noButton,
  .yesButton
 {
    font-size: 1.5vw;
    border-width: 0.2vw;
  }

  .taskCard{
    font-size: 2vw;
    width: 50%;
  }
  .title {
    font-size: 3vw;
    margin-top: 2vw;
  }

  .intro{
    margin-top: 0;
  }

  .introContent{
    margin-top: 0%;
  }

  .inputField{
    font-size: 2vw;
  }
}


.finalTaskCard {
  background-color: #f4f4f8; /* Slightly different background color for distinction */
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  width: 80%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finalTitle {
  font-size: 4vw;
  color: #333;
  margin-bottom: 2vw;
}

.finalSubtitle, .finalInstructions {
  font-size: 3vw;
  color: #666;
}

.finalWordContainer {
  display: flex;
  justify-content: center;
  gap: 1.2vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.finalInputField {
  font-size: 3vw;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 3vw;
  text-align: center;
}

.finalInputField.correct {
  border-color: green;
  background-color: #d4edda;
}

.finalInputField.incorrect {
  border-color: red;
  background-color: #f8d7da;
}

.finalCongratulations {
  background-color: #e9ecef;
  border-radius: 4px;
  color: gold;
  font-size: 3vw;
  margin-top: 1rem;
  font-weight: bold;
}