/* Base styles - Mobile first approach */
body {
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #3d3d3d;
  font-family: "Archivo";
  color: #fff;
  font-size: 4vw; /* Adjust size for mobile */
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: "Archivo";
}

.bottom-text {
  text-align: center;
  margin-bottom: 10vw;
  margin-top: 5vw;
  bottom: 0px;
  width: 100%;
}

ul {
  margin-top: 10vw;
  margin: 0;
  padding: 0;
  display: flex;
}

.lubim {
  display: flex;
  color: #fff; /* Farba pre "láska" */
  font-size: 6vw; /* Relatívna veľkosť písma pre "láska" */
  /* margin-top: -1vw; */
  margin-bottom: 5vw;
  margin-right: auto;
}

.abejka {
  margin-left: 1vw;
  font-family: "Pacifico", cursive;
  color: #ff69b4;
  margin-top: -2.1vw;
}

.martin {
  margin-left: 1vw;
  font-family: "Pacifico", cursive;
  color: #ffd90d;
}

ul li {
  font-family: "Archivo";
  margin-bottom: 1vw;
  list-style: none;
  color: #afafaf;
  font-size: 6vw;
  letter-spacing: 1vw;
  animation: lighting 2.1s linear infinite;
}

@keyframes lighting {
  0% {
    color: #afafaf;
    text-shadow: none;
  }

  90% {
    color: #6e6e6e;
    text-shadow: none;
  }

  100% {
    color: #ff69b4;
    text-shadow: 0 0 7px #ff69b4, 0 0 50px #ff6c00;
  }
}

ul li:nth-child(1) {
  animation-delay: 0;
}

ul li:nth-child(2) {
  animation-delay: 0.1s;
}

ul li:nth-child(3) {
  animation-delay: 0.2s;
}

ul li:nth-child(4) {
  animation-delay: 0.3s;
}

ul li:nth-child(5) {
  animation-delay: 0.4s;
}

ul li:nth-child(6) {
  animation-delay: 0.5s;
  margin-left: 1vw;
}

ul li:nth-child(7) {
  animation-delay: 0.6s;
}

ul li:nth-child(8) {
  animation-delay: 0.7s;
}
ul li:nth-child(9) {
  animation-delay: 0.8s;
  margin-left: 1vw;
}
ul li:nth-child(10) {
  animation-delay: 0.9s;
}
ul li:nth-child(11) {
  animation-delay: 1s;
}
ul li:nth-child(12) {
  animation-delay: 1.1s;
}
ul li:nth-child(13) {
  animation-delay: 1.2s;
}
ul li:nth-child(14) {
  animation-delay: 1.3s;
}
ul li:nth-child(15) {
  animation-delay: 1.4s;
}
ul li:nth-child(16) {
  animation-delay: 1.5s;
  margin-left: 1vw;
}
ul li:nth-child(17) {
  animation-delay: 1.6s;
}
ul li:nth-child(18) {
  animation-delay: 1.7s;
}
ul li:nth-child(19) {
  animation-delay: 1.8s;
}
ul li:nth-child(20) {
  animation-delay: 1.9s;
}
ul li:nth-child(21) {
  animation-delay: 2s;
}
ul li:nth-child(22) {
  animation-delay: 2.1s;
}
ul li:nth-child(23) {
  animation-delay: 2.2s;
}

.button-23-pink {
  margin-top: 10vw;
  background-color: transparent;
  border: 2px solid #f7cac9;
  border-radius: 8px;
  color: #f7cac9;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  margin-right: 4.5vw;
}
.button-23-yellow {
  margin-left: 4vw;
  background-color: transparent;
  border: 2px solid #f7b801;
  border-radius: 8px;
  color: #f7b801;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23-purple {
  margin-top: 5vw;
  background-color: transparent;
  border: 2px solid #7678ed;
  border-radius: 8px;
  color: #7678ed;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}
.button-23-green {
  margin-top: 5vw;
  margin-bottom: 30vw;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

@media (min-width: 950px) {
  .button-23-purple{
    font-size:2vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  
  .button-23-yellow{
    font-size: 2vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  
  .button-23-pink{
    margin-right: 1vw;
    font-size: 2vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  .button-23-green{
    margin-right: 1vw;
    font-size: 2vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .lubim {
    font-size: 3vw;
  }

  .abejka{
    margin-top: -1.2vw;
    font-size: 3vw;
  }

  .bottom-text {
    font-size: 2vw;
  }


  ul li {
    font-family: "Archivo";
    margin-bottom: 1vw;
    margin-top: 10vw;
    list-style: none;
    color: #afafaf;
    font-size: 3vw;
    letter-spacing: 1vw;
    animation: lighting 2.1s linear infinite;
  }
}
