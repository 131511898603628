.playScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-color: #7678ed;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 10px;
}

.card {
  border-radius: 8px;
  width: 20vw;
  height: 20vw;
  background: linear-gradient(#f7cac9, #f2a8a6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.card img {
  box-shadow: 0px 1px 20px 0px #f7cac9;
  max-width: 100%;
  max-height: 100%;
  display: none;
  border-radius: 8px;
}

.title {
  text-align: center;
  margin-bottom: 4vw;
  font-size: 5.2vw;
  font-family: "Archivo";
}

.jojo {
  color: #f7cac9;
  font-family: "Pacifico", cursive;
}

.count {
  color: #f7b801;
  font-family: "Pacifico";
  font-size: 5vw;
}

.card img.visible {
  display: block;
}

.game-controls {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

button {
  margin-left: 3vw;
  margin-bottom: 3vw;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 4vw;
  margin: 0;
  outline: none;
  padding: 1vw;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

@media (min-width: 950px) {
  button {
    font-size: 1vw;
    border: 5px solid;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .title {
    font-size: 3vw;
    margin-bottom: 1vw;
  }

  .card {
    width: 150px;
    height: 150px;
  }

  p {
    font-size: 3vw;
    margin: 0;
  }
}
